<template>
  <travio-center-container grid-width="1/2" pageTitle="Application Details">
    <vx-card>
      <vs-tabs class="tabs-shadow-none">
        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-settings" label="General Info">
          <div class="flex flex-wrap items-center mb-base mt-6">
            <vs-avatar :src="form.logoUrl" icon="cloud_upload" size="70px" class="mr-4 mb-4 company-logo-avatar" />
          </div>
          <vs-input class="w-full mt-4" label="Application Name*" v-model="form.applicationName" disabled />
          <div class="mt-4">
            <label class="text-sm">Web Platform</label>
            <v-select :value="selectedWebPlatform" append-to-body :options="webPlatformOptions" disabled />
          </div>
          <div class="mt-4">
            <label class="text-sm">Subscription*</label>
            <v-select :value="selectedSubscription" append-to-body :options="subscriptionOptions" disabled />
          </div>

          <div class="flex items-center mt-4">
            <vs-switch v-model="form.isDemo" disabled />
            <span class="ml-4">Is Demo</span>
          </div>
          <div class="flex items-center mt-4">
            <vs-switch v-model="form.isActive" disabled />
            <span class="ml-4">Is Active</span>
          </div>          
         
        </vs-tab>
        
        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-map-pin" label="Location">
          <div class="mt-4">
            <label class="text-sm">Region*</label>
            <v-select :value="selectedRegion" @input="setSelectedRegion" append-to-body :options="regionOptions" disabled />
          </div>
          <vs-input class="w-full mt-4" label="Address 1" v-model="form.address1" disabled />
          <vs-input class="w-full mt-4" label="Address 2" v-model="form.address2" disabled />

          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full mt-4" label="City" v-model="form.city" disabled />
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full mt-4" label="State" v-model="form.state" disabled />
            </div>
          </div>

          <!-- Country -->
          <div class="mt-4">
            <label class="text-sm">Country</label>
            <v-select :value="selectedCountry" append-to-body :options="countryOptions" disabled />
          </div>
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-calendar" label="Booking">
          <!-- Booking Col -->
          <div>
            <vs-input class="w-full mt-4" label="Booking Email Name" v-model="form.bookingEmailName" disabled />
            <vs-input class="w-full mt-4" label="Booking Email" v-model="form.bookingEmail" disabled />
            <vs-input class="w-full mt-4" label="Booking Telephone Name" v-model="form.bookingTelephoneName" disabled />
            <vs-input class="w-full mt-4 mb-8" label="Booking Telephone" v-model="form.bookingTelephone" disabled />
          </div>
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-info" label="Support">
          <vs-input class="w-full mt-4" label="Support Email Name" v-model="form.supportEmailName" disabled />
          <vs-input class="w-full mt-4" label="Support Email" v-model="form.supportEmail" disabled />
          <vs-input class="w-full mt-4" label="Support Telephone Name" v-model="form.supportTelephoneName" disabled />
          <vs-input class="w-full mt-4" label="Support Telephone" v-model="form.supportTelephone" disabled />
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-code" label="API">
          <div class="vx-row mt-4">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Allow Public API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-switch v-model="form.allowPublicApiKey" disabled />
            </div>
          </div>

          <div class="vx-row mt-4" v-if="form.allowPublicApiKey">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Public API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-input class="w-full" v-model="form.publicApiKey" disabled />
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-if="form.allowPublicApiKey">
            <vs-button 
              v-clipboard:copy="form.publicApiKey"
              v-clipboard:success="onCopy" 
              icon-pack="feather" icon="icon-clipboard" class="mt-4"
              title="Copy To Clipboard"
            >{{isSmallerScreen ? '': 'Copy To Clipboard'}}
            </vs-button>
          </div>

          <div class="vx-row mt-12">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Allow Private API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-switch v-model="form.allowPrivateApiKey" disabled />
            </div>
          </div>
          <div class="vx-row mt-12" v-if="form.allowPrivateApiKey">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Private API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-input class="w-full" v-model="form.privateApiKey" disabled />
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-if="form.allowPrivateApiKey">
            <vs-button 
              v-clipboard:copy="form.privateApiKey" 
              v-clipboard:success="onCopy" 
              title="Copy To Clipboard"
              icon-pack="feather" icon="icon-clipboard" class="mt-4">{{isSmallerScreen ? '': 'Copy To Clipboard'}}
            </vs-button>
          </div>
        </vs-tab>
        
      </vs-tabs>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'
import { v4 as uuidv4 } from 'uuid';

export default {
  props: { 
    companyId: { required: true },
    applicationId: { required: true }
  },
  data () {
    return {
      form: new Form({
        applicationName: '',
        regionId: 0,
        subscriptionId: 0,
        webPlatformId: null,
        logoUrl: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        countryCode: '',

        bookingEmailName: '',
        bookingEmail: '',
        bookingTelephoneName: '',
        bookingTelephone: '',

        supportEmailName: '',
        supportEmail: '',
        supportTelephoneName: '',
        supportTelephone: '',

        publicApiKey: '',
        allowPublicApiKey: false,
        privateApiKey: '',
        allowPrivateApiKey: false,

        logoFile: null
      }),
      selectedCountry: null,
      selectedRegion: null,
      selectedSubscription: null,
      selectedWebPlatform: null,
      subscriptionOptions: [],
      regionOptions: [],
      webPlatformOptions: [],
      hasDataLoaded: false
    }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted () {
    this.hasDataLoaded = false
    this.$http.get(`api/companies/${this.companyId}/applications/lookups`)
    .then(response => {
      this.regionOptions = response.data.regionOptions
      this.subscriptionOptions = response.data.subscriptionOptions
      this.webPlatformOptions = response.data.webPlatformOptions

      this.$http.get(`api/admin/companies/${this.companyId}/apps/${this.applicationId}`)
      .then(response => {
        this.form = new Form(Object.assign(this.form.data(), response.data))
        this.selectedCountry = this.countryOptions.find(x => x.code == response.data.countryCode) || null
        this.selectedSubscription = this.subscriptionOptions.find(x => x.code == response.data.subscriptionId) || {};
        this.selectedWebPlatform = this.webPlatformOptions.find(x => x.code == response.data.webPlatformId) || {};
        this.selectedRegion = this.regionOptions.find(x => x.code == response.data.regionId) || {};
        this.hasDataLoaded = true
      })
      .catch(err => console.error(err) )

    })
    .catch(err => console.error(err) )

    
  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    onCopy () {
      this.$_notifySuccess('API Key copied to clipboard')
    },
    handleReset () {
      this.selectedCountry = null
      this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push({ name: 'admin-crm-company-edit', params: { companyId: this.companyId, presetTabIndex: 2 } })
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }

  .fixed-height-app-edit {
    height: calc(var(--vh, 1vh) * 100 - 38rem);
    /* overflow-y: hidden; */
    overflow-x: hidden;
    position: relative;
  }
</style>